import React, { useState, useRef, useEffect } from "react";

const CollapsibleRow = ({ title, open, close, link }) => {
  const contentRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");
  useEffect(() => {
    if (contentRef.current) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    }
  }, []);

  return (
    <div className="collapsible-row" onClick={close}>
      <div className="row-header">
        <span>{title}</span>
      </div>
      <div
        ref={contentRef}
        style={{
          height: open ? maxHeight : "0",
          overflow: "hidden",
          transition: "height 0.3s ease-out",
        }}
      >
        <div className="links-container">
          {link.map(
            (
              link,
              index, // eslint-disable-next-line
              // eslint-disable-next-line
            ) => (
              <a key={index} href={link.href} target="_blank">
                {link.text}
              </a>
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default CollapsibleRow;
