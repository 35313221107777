import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!localStorage.getItem("firstVisit")) {
      if (!localStorage.getItem("firstVisit") && !token) {
        navigate("/login");
        return;
      } else if (localStorage.getItem("firstVisit") && !token) {
        navigate("/permission-denied");
      }
    } else if (localStorage.getItem("firstVisit")) {
      navigate("/permission-denied");
    }
  }, [navigate]);

  return children;
};

export default ProtectedRoute;
