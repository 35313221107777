import { React, useEffect } from "react";
import BeehiveImage from "./assets/beehive2.png";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";

const Header = () => (
  <header>
    <h1>Beehive Services Status Dashboard</h1>
    <img src={BeehiveImage} alt="Beehive" className="header-image"></img>
  </header>
);

function LoginPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    console.log();
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleLoginSuccess = async (response) => {
    localStorage.setItem("token", response.credential);
    navigate("/");
  };

  const handleLoginFailure = (response) => {
    console.error("Login Failed:", response);
  };

  return (
    <div>
      <Header />
      <div className="card">
        <div className="card-body">
          <GoogleLogin
            size="large"
            theme="filled_blue"
            width="300"
            shape="rectangular"
            onSuccess={handleLoginSuccess}
            onError={handleLoginFailure}
          />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
