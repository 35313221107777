import { googleLogout } from "@react-oauth/google";
import "./StatusDashboard.css";

const SignOutButton = () => {
  const onLogoutSuccess = () => {
    console.log("Logout successful");
    localStorage.removeItem("token");
    window.location.reload();
  };

  const handleLogout = () => {
    googleLogout();
    onLogoutSuccess();
  };

  return (
    <button onClick={handleLogout} className="logout-button">
      Sign Out
    </button>
  );
};

export default SignOutButton;
