import React, { useState, useEffect, useCallback } from "react";
import StatusDashboard from "./StatusDashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./LoginPage";
import ProtectedRoute from "./ProtectedRoute";
import PermissionDenied from "./PermissionDenied.jsx";
import axios from "axios";

function App() {
  const [services, setServices] = useState([]);

  const RetreiveStatus = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const response = await axios
          .get("https://beekeeper.uw2.beehive.liveeo.io/apprunner-status", {
            headers: {
              Authorization: token,
            },
          })
          .catch((error) => {
            if (error) {
              console.log(error);
              localStorage.removeItem("token");
              window.location.href = "/login";
            }
          });
        const serviceStatuses = [];
        Object.entries(response.data).forEach((result) => {
          serviceStatuses.push({
            name: result[0],
            beehive_name: result[1].beehive_name,
            status: result[1].current_status,
            logs: result[1].logs,
            gitlab: result[1].gitlab,
            metric: result[1].metric,
          });
        });
        setServices(serviceStatuses);
      }
    } catch (error) {
      if (error) {
        console.log(`Error fetching service status`, error);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    RetreiveStatus().catch((e) => console.log(e));
    const intervalId = setInterval(RetreiveStatus, 8000);
    return () => clearInterval(intervalId);
  }, [RetreiveStatus]);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              {" "}
              <StatusDashboard services={services} />{" "}
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/permission-denied" element={<PermissionDenied />} />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </Router>
  );
}

export default App;
